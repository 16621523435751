import { __decorate, __extends, __read } from "tslib";
import { Component, Watch, Vue } from 'vue-property-decorator';
import CustomTitle from '@/components/CustomTitle.vue';
import Pagination from '@/components/Pagination.vue';
import { publicModule } from '@/store/modules/public';
import { systemModule } from '@/store/modules/system';
import { aboutModule } from '@/store/modules/about';
var AboutUs = (function (_super) {
    __extends(AboutUs, _super);
    function AboutUs() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.historyKey = '';
        _this.certificateKey = '40';
        _this.page = 1;
        _this.rows = 6;
        _this.dialogVisible = false;
        return _this;
    }
    AboutUs.prototype.mounted = function () {
        aboutModule.getCompanyInfo();
        aboutModule.getVideoList();
        aboutModule.getHistoryParams();
        this.getCertificateList();
    };
    Object.defineProperty(AboutUs.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AboutUs.prototype, "certificateTitleList", {
        get: function () {
            return [
                {
                    id: '40',
                    name: this.$t('aboutUs.enterpriseHonor')
                },
                {
                    id: '41',
                    name: this.$t('aboutUs.systemCertificate')
                },
                {
                    id: '39',
                    name: this.$t('aboutUs.patentCertificate')
                },
                {
                    id: '38',
                    name: this.$t('aboutUs.copyright')
                },
                {
                    id: '42',
                    name: this.$t('aboutUs.productTesting')
                }
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AboutUs.prototype, "innerWidth", {
        get: function () {
            return publicModule.innerWidth;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AboutUs.prototype, "companyInfo", {
        get: function () {
            return aboutModule.companyInfo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AboutUs.prototype, "companyIntroduce", {
        get: function () {
            if (aboutModule.companyInfo.info_home_intro) {
                return JSON.parse(aboutModule.companyInfo.info_home_intro.value);
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AboutUs.prototype, "companyProfile", {
        get: function () {
            if (aboutModule.companyInfo.info_profile) {
                return aboutModule.companyInfo.info_profile.value;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AboutUs.prototype, "videoList", {
        get: function () {
            return aboutModule.videoList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AboutUs.prototype, "historyParams", {
        get: function () {
            var _a;
            if (Object.keys(aboutModule.historyParams).length) {
                _a = __read(Object.keys(aboutModule.historyParams), 1), this.historyKey = _a[0];
            }
            return aboutModule.historyParams;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AboutUs.prototype, "certificateList", {
        get: function () {
            return aboutModule.certificateList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AboutUs.prototype, "total", {
        get: function () {
            return aboutModule.certificateTotal;
        },
        enumerable: false,
        configurable: true
    });
    AboutUs.prototype.pageWatch = function () {
        this.getCertificateList();
    };
    AboutUs.prototype.historyClick = function (val) {
        this.historyKey = val;
    };
    AboutUs.prototype.certificateClick = function (val) {
        this.certificateKey = val;
        this.page = 1;
        this.getCertificateList();
    };
    AboutUs.prototype.getCertificateList = function () {
        aboutModule.getCertificateList({
            page: this.page,
            rows: this.rows,
            id: this.certificateKey
        });
    };
    AboutUs.prototype.switchHistory = function (type) {
        var index = Object.keys(this.historyParams).indexOf(this.historyKey);
        if (type) {
            if (index < Object.keys(this.historyParams).length - 1) {
                this.historyKey = Object.keys(this.historyParams)[index + 1];
            }
        }
        else if (index > 0) {
            this.historyKey = Object.keys(this.historyParams)[index - 1];
        }
    };
    __decorate([
        Watch('$data.page')
    ], AboutUs.prototype, "pageWatch", null);
    AboutUs = __decorate([
        Component({
            components: { CustomTitle: CustomTitle, Pagination: Pagination }
        })
    ], AboutUs);
    return AboutUs;
}(Vue));
export default AboutUs;
