import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { publicModule } from '@/store/modules/public';
var CustomTitle = (function (_super) {
    __extends(CustomTitle, _super);
    function CustomTitle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CustomTitle.prototype, "lang", {
        get: function () {
            return publicModule.lang;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ required: true })
    ], CustomTitle.prototype, "title", void 0);
    __decorate([
        Prop({ default: '' })
    ], CustomTitle.prototype, "subtitle", void 0);
    __decorate([
        Prop({ default: '#000096' })
    ], CustomTitle.prototype, "titleColor", void 0);
    __decorate([
        Prop({ default: '#666' })
    ], CustomTitle.prototype, "subTitleColor", void 0);
    __decorate([
        Prop({ default: '46px' })
    ], CustomTitle.prototype, "titleSize", void 0);
    __decorate([
        Prop({ default: '26px' })
    ], CustomTitle.prototype, "subTitleSize", void 0);
    CustomTitle = __decorate([
        Component
    ], CustomTitle);
    return CustomTitle;
}(Vue));
export default CustomTitle;
